import Vue from 'vue';
import Router from 'vue-router';
import { PAGE_CODE, PERMISSION_TYPE } from './core/plugins/constants';
import { RESET_LAYOUT_CONFIG } from '@/core/services/store/config.module';
import { SET_PERMISSIONS } from '@/core/services/store/auth.module';
import intersection from 'lodash/intersection';
import groupBy from 'lodash/groupBy';
import MgrService from '@/core/services/security.service';
import store from '@/core/services/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  duplicateNavigationPolicy: 'reload',
  routes: [
    {
      path: '/',
      component: () => import('@/view/layout/Layout'),
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '/',
          name: 'dashboard',
          component: () => import('@/view/pages/Dashboard.vue'),
        },
        // USER DASHBOARD: BEGIN
        {
          path: 'user_dashboard',
          name: 'user_dashboard_find',
          component: () =>
            import('@/view/pages/user_dashboard/UserDashboard.vue'),
          meta: {
            requiresAuth: true,
            permissions: [PERMISSION_TYPE.READ_ALL, PERMISSION_TYPE.READ_OWNER],
            pageCode: PAGE_CODE.USER_DASHBOARD,
            breadcrumb: [
              {
                name: 'Quản lý khách hàng',
              },
              {
                name: 'User dashboard',
              },
              {
                name: 'User dashboard',
                active: true,
              },
            ],
          },
        },
        {
          path: 'user_dashboard/:id',
          name: 'user_dashboard',
          component: () => import('@/view/pages/user_dashboard/index.vue'),
          redirect: '/user_dashboard/:id/over_view',
          meta: {
            permissions: [PERMISSION_TYPE.READ_ALL, PERMISSION_TYPE.READ_OWNER],
            pageCode: PAGE_CODE.USER_DASHBOARD,
          },
          children: [
            {
              path: 'over_view',
              name: 'over_view',
              component: () =>
                import('@/view/pages/user_dashboard/pages/OverView.vue'),
              meta: {
                requiresAuth: true,
                permissions: [
                  PERMISSION_TYPE.READ_ALL,
                  PERMISSION_TYPE.READ_OWNER,
                ],
                pageCode: PAGE_CODE.USER_DASHBOARD,
                breadcrumb: [
                  {
                    name: 'User dashboard',
                  },
                  {
                    name: 'Tổng hợp',
                  },
                  {
                    name: 'Chỉ số tổng hợp',
                    active: true,
                  },
                ],
              },
            },
            {
              path: 'biological',
              name: 'biological',
              component: () =>
                import('@/view/pages/user_dashboard/pages/Biological.vue'),
              meta: {
                requiresAuth: true,
                permissions: [
                  PERMISSION_TYPE.READ_ALL,
                  PERMISSION_TYPE.READ_OWNER,
                ],
                pageCode: PAGE_CODE.USER_DASHBOARD,
                breadcrumb: [
                  {
                    name: 'User dashboard',
                  },
                  {
                    name: 'Chỉ số sinh học',
                  },
                  {
                    name: 'Chỉ số sinh học',
                    active: true,
                  },
                ],
              },
            },
            {
              path: 'schedule',
              name: 'schedule',
              component: () =>
                import('@/view/pages/user_dashboard/pages/Schedule.vue'),
              meta: {
                requiresAuth: true,
                permissions: [
                  PERMISSION_TYPE.READ_ALL,
                  PERMISSION_TYPE.READ_OWNER,
                ],
                pageCode: PAGE_CODE.USER_DASHBOARD,
                breadcrumb: [
                  {
                    name: 'User dashboard',
                  },
                  {
                    name: 'Lịch trình',
                  },
                  {
                    name: 'Lịch trình',
                    active: true,
                  },
                ],
              },
            },
            {
              path: 'lessons',
              name: 'lessons',
              component: () =>
                import('@/view/pages/user_dashboard/pages/Lesson.vue'),
              meta: {
                requiresAuth: true,
                permissions: [
                  PERMISSION_TYPE.READ_ALL,
                  PERMISSION_TYPE.READ_OWNER,
                ],
                pageCode: PAGE_CODE.USER_DASHBOARD,
                breadcrumb: [
                  {
                    name: 'User dashboard',
                  },
                  {
                    name: 'Bài học',
                  },
                  {
                    name: 'Danh sách bài học',
                    active: true,
                  },
                ],
              },
            },
            {
              path: 'survey_result',
              name: 'survey_result',
              component: () =>
                import('@/view/pages/user_dashboard/pages/SurveyResult.vue'),
              meta: {
                requiresAuth: true,
                permissions: [
                  PERMISSION_TYPE.READ_ALL,
                  PERMISSION_TYPE.READ_OWNER,
                ],
                pageCode: PAGE_CODE.USER_DASHBOARD,
                breadcrumb: [
                  {
                    name: 'User dashboard',
                  },
                  {
                    name: 'Kết quả khảo sát',
                  },
                  {
                    name: 'Danh sách kết quả khảo sát',
                    active: true,
                  },
                ],
              },
            },
            {
              path: 'student_report',
              name: 'student_report',
              component: () =>
                import('@/view/pages/user_dashboard/pages/StudentReport.vue'),
              meta: {
                requiresAuth: true,
                permissions: [
                  PERMISSION_TYPE.READ_ALL,
                  PERMISSION_TYPE.READ_OWNER,
                ],
                pageCode: PAGE_CODE.USER_DASHBOARD,
                breadcrumb: [
                  {
                    name: 'User dashboard',
                  },
                  {
                    name: 'Báo cáo học viên',
                  },
                  {
                    name: 'Danh sách báo cáo học viên',
                    active: true,
                  },
                ],
              },
            },
          ],
        },
        // USER DASHBOARD: END

        // PATIENT: BEGIN
        {
          path: 'patient',
          name: 'patient_list',
          component: () => import('@/view/pages/patient/patientList.vue'),
        },
        {
          path: 'patient/:form_type/:id?',
          name: 'patient_detail',
          props: true,
          component: () => import('@/view/pages/Dashboard.vue'),
          meta: {},
        },
        {
          path: 'history_kpis',
          name: 'history_kpis',
          component: () =>
            import('@/view/pages/history_input_kpis/historyKPIsList.vue'),
        },
        // PATIENT: END
        // MANAGE USER GROUPS: BEGIN
        {
          path: 'user_group',
          name: 'user_group_list',
          component: () => import('@/view/pages/user_group/userGroupsList.vue'),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                name: 'Danh sách nhóm người dùng',
                link: 'user_group_list',
                active: true,
              },
            ],
          },
        },
        {
          path: 'user_group/:form_type/:id?',
          name: 'user_group_detail',
          props: true,
          component: () =>
            import('@/view/pages/user_group/userGroupDetail.vue'),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                name: 'Danh sách nhóm người dùng',
                link: 'user_group_list',
              },
              {
                createName: 'Thêm nhóm người dùng',
                editName: 'Chỉnh sửa nhóm người dùng',
                link: 'user_group_detail',
                active: true,
              },
            ],
          },
        },
        // MANAGE USER GROUPS: END
        // MANAGE ACCOUNT APP: BEGIN
        {
          path: 'user_app',
          name: 'user_app_list',
          component: () => import('@/view/pages/user_app/userAppList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.ACCOUNT_ACCOUNT_APP,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý tài khoản App',
              },
              {
                name: 'Danh sách tài khoản App',
              },
              {
                name: 'Danh sách tài khoản App',
                active: true,
              },
            ],
          },
        },
        {
          path: 'user_app/:id?',
          name: 'user_app_view',
          props: true,
          component: () => import('@/view/pages/user_app/userAppView.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.ACCOUNT_ACCOUNT_APP,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý tài khoản App',
                link: 'user_app_list',
              },
              {
                name: 'Chi tiết tài khoản App',
              },
              {
                name: 'Chi tiết tài khoản App',
                active: true,
              },
            ],
          },
        },
        {
          path: 'user_app/:form_type/:id?',
          name: 'user_app_update',
          props: true,
          component: () => import('@/view/pages/user_app/userAppUpdate.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.ACCOUNT_ACCOUNT_APP,
            permissions: [PERMISSION_TYPE.WRITE],
            breadcrumb: [
              {
                name: 'Quản lý tài khoản App',
                link: 'user_app_list',
              },
              {
                name: 'Chỉnh sửa tài khoản App',
              },
              {
                editName: 'Chỉnh sửa tài khoản App',
                active: true,
              },
            ],
          },
        },
        // MANAGE TRACKING SHARE APP
        {
          path: 'tracking_share_app',
          name: 'tracking_share_app',
          component: () => import('@/view/pages/tracking_share_app/List.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.TRACKING_SHARE_APP,
            permissions: [PERMISSION_TYPE.READ_OWNER, PERMISSION_TYPE.READ_ALL],
            breadcrumb: [
              {
                name: 'Trang chủ',
              },
              {
                name: 'Tracking share app',
              },
              {
                name: 'Quản lý giới thiệu',
                active: true,
              },
            ],
          },
        },
        // MANAGE ACCOUNT APP: END
        // MANAGE ACCOUNT PORTAL: BEGIN
        {
          path: 'user_portal',
          name: 'user_portal_list',
          component: () =>
            import('@/view/pages/user_portal/userPortalList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.ACCOUNT_ACCOUNT_PORTAL,
            permissions: [PERMISSION_TYPE.READ_ALL, PERMISSION_TYPE.READ_OWNER],
            breadcrumb: [
              {
                name: 'Quản lý tài khoản Portal',
              },
              {
                name: 'Danh sách tài khoản Portal',
              },
              {
                name: 'Danh sách tài khoản Portal',
                active: true,
              },
            ],
          },
        },
        {
          path: 'user_portal/view/:id',
          name: 'user_portal_view',
          props: true,
          component: () =>
            import('@/view/pages/user_portal/userPortalView.vue'),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                name: 'Danh mục tài khoản Portal',
                link: 'user_portal_list',
              },
              {
                name: 'Chi tiết tài khoản Portal',
                link: 'user_portal_view',
                active: true,
              },
            ],
          },
        },
        {
          path: 'user_portal/:form_type/:id?',
          name: 'user_portal_detail',
          props: true,
          component: () =>
            import('@/view/pages/user_portal/userPortalDetail.vue'),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                name: 'Danh mục tài khoản Portal',
                link: 'user_portal_list',
              },
              {
                createName: 'Thêm mới tài khoản Portal',
                editName: 'Chỉnh sửa tài khoản Portal',
                link: 'user_portal_detail',
                active: true,
              },
            ],
          },
        },
        {
          path: 'coach_profile/create',
          name: 'coach_profile_create',
          props: true,
          component: () =>
            import('@/view/pages/coach_profile/CoachProfileCreate.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.ACCOUNT_ACCOUNT_PORTAL,
            permissions: [
              PERMISSION_TYPE.WRITE_ALL,
              PERMISSION_TYPE.WRITE_OWNER,
            ],
            breadcrumb: [
              {
                name: 'Quản lý tài khoản Portal',
                link: 'user_portal_list',
              },
              {
                name: 'Thêm mới tài khoản Portal',
              },
              {
                name: 'Tạo mới tài khoản',
                link: 'coach_profile_create',
                active: true,
              },
            ],
          },
        },
        {
          path: 'coach_profile/:id',
          name: 'coach_profile_detail',
          props: (route) => ({
            id: route.params.id,
          }),
          component: () =>
            import('@/view/pages/coach_profile/CoachProfileDetail.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.ACCOUNT_ACCOUNT_PORTAL,
            permissions: [PERMISSION_TYPE.READ_ALL, PERMISSION_TYPE.READ_OWNER],
            breadcrumb: [
              {
                name: 'Quản lý tài khoản Portal',
                link: 'user_portal_list',
              },
              {
                name: 'Chi tiết tài khoản Portal',
              },
              {
                name: 'Chi tiết tài khoản',
                link: 'coach_profile_create',
                active: true,
              },
            ],
          },
        },
        {
          path: 'coach_profile/edit/:id',
          name: 'coach_profile_edit',
          component: () =>
            import('@/view/pages/coach_profile/CoachProfileUpdate.vue'),
          props: (route) => ({
            id: route.params.id,
          }),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.ACCOUNT_ACCOUNT_PORTAL,
            permissions: [
              PERMISSION_TYPE.WRITE_ALL,
              PERMISSION_TYPE.WRITE_OWNER,
            ],
            breadcrumb: [
              {
                name: 'Quản lý tài khoản Portal',
                link: 'user_portal_list',
              },
              {
                name: 'Chỉnh sửa tài khoản Portal',
              },
              {
                name: 'Chỉnh sửa tài khoản',
                link: 'coach_profile_create',
                active: true,
              },
            ],
          },
        },
        // MANAGE ACCOUNT PORTAL: END
        // PERMISSION_ROLE: BIGIN
        {
          path: 'permission_role',
          name: 'permission_role',
          component: () =>
            import('@/view/pages/permission_role/permission_role.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.ACCOUNT_ROLE,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý vai trò',
              },
              {
                name: 'Danh sách vai trò',
              },
              {
                name: 'Danh sách vai trò',
                active: true,
              },
            ],
          },
        },
        // PERMISSION_ROLE: END
        // CUSTOMER MANAGEMENT: BEGIN
        {
          path: 'active_account',
          name: 'active_account_list',
          component: () =>
            import('@/view/pages/active_account/AcctiveAccount.vue'),
          meta: {
            pageCode: PAGE_CODE.CUSTOMER_GROUP,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Kích hoạt tài khoản',
              },
              {
                name: 'Danh sách tài khoản',
              },
              {
                name: 'Danh sách kích hoạt tài khoản',
                active: true,
              },
            ],
          },
        },
        {
          path: 'customer',
          name: 'customer',
          component: () => import('@/view/pages/customer/CustomerList'),
          meta: {
            breadcrumb: [
              {
                name: 'Khách hàng của tôi',
              },
              {
                name: 'Danh sách khách hàng',
              },
              {
                name: 'Danh sách khách hàng của tôi',
                active: true,
              },
            ],
          },
        },
        // CUSTOMER MANAGEMENT: END
        // COURSE: BEGIN
        {
          path: 'course',
          name: 'course',
          component: () => import('@/view/pages/course/course.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Khoá học',
                link: 'course',
                active: true,
              },
            ],
          },
        },
        {
          path: 'course/:id?',
          name: 'course_detail',
          props: true,
          component: () => import('@/view/pages/course/courseDetail.vue'),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                name: 'Khoá học',
                link: 'course',
              },
              {
                name: 'Chi tiết khoá học',
                link: 'course_detail',
                active: true,
              },
            ],
          },
        },
        {
          path: 'module',
          name: 'module',
          component: () => import('@/view/pages/module/ModuleList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.LESSON_MODULE,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý chủ đề',
              },
              {
                name: 'Danh sách chủ đề',
              },
              {
                name: 'Danh sách chủ đề',
                active: true,
              },
            ],
          },
        },
        {
          path: 'lesson',
          name: 'lesson',
          component: () => import('@/view/pages/lesson/LessonList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.LESSON,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý bài học',
              },
              {
                name: 'Danh sách bài học',
              },
              {
                name: 'Danh sách bài học',
                active: true,
              },
            ],
          },
        },
        {
          path: 'lesson/create',
          name: 'lesson_create',
          component: () => import('@/view/pages/lesson/LessonEdit.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.LESSON,
            permissions: [PERMISSION_TYPE.WRITE],
            breadcrumb: [
              {
                name: 'Quản lý bài học',
                link: 'lesson',
              },
              {
                name: 'Thêm mới bài học',
                link: 'lesson_create',
              },
              {
                name: 'Tạo mới bài học',
                active: true,
              },
            ],
          },
        },
        {
          path: 'lesson/edit/:id',
          name: 'lesson_edit',
          component: () => import('@/view/pages/lesson/LessonEdit.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.LESSON,
            permissions: [PERMISSION_TYPE.WRITE],
            breadcrumb: [
              {
                name: 'Quản lý bài học',
                link: 'lesson',
              },
              {
                name: 'Chỉnh sửa bài học',
              },
              {
                name: 'Chỉnh sửa bài học',
                active: true,
              },
            ],
          },
        },
        {
          path: 'lesson/view/:id',
          name: 'lesson_view',
          component: () => import('@/view/pages/lesson/LessonDetail.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.LESSON,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý bài học',
                link: 'lesson',
              },
              {
                name: 'Chi tiết bài học',
              },
              {
                name: 'Chi tiết bài học',
                active: true,
              },
            ],
          },
        },
        // COURSE: END
        // LEVEL: BEGIN
        {
          path: 'level',
          name: 'level',
          component: () => import('@/view/pages/level/LevelList.vue'),
          meta: {
            pageCode: PAGE_CODE.LESSON_LEVEL,
            permissions: [PERMISSION_TYPE.READ],
            requiresAuth: true,
            breadcrumb: [
              {
                name: 'Quản lý cấp độ',
              },
              {
                name: 'Danh sách cấp độ',
              },
              {
                name: 'Danh sách cấp độ',
                active: true,
              },
            ],
          },
        },
        // LEVEL: END
        // LEVEL: BEGIN
        {
          path: 'voucher',
          name: 'voucher',
          component: () => import('@/view/pages/voucher/List.vue'),
          meta: {
            pageCode: PAGE_CODE.VOUCHERS,
            permissions: [PERMISSION_TYPE.READ],
            requiresAuth: true,
            breadcrumb: [
              {
                name: 'Quản lý Voucher',
              },
              {
                name: 'Danh sách Voucher',
              },
              {
                name: 'Danh sách Voucher',
                active: true,
              },
            ],
          },
        },
        {
          path: 'report1',
          name: 'report1',
          component: () => import('@/view/pages/Report/Report1.vue'),
          meta: {
             pageCode: PAGE_CODE.REPORT,
            permissions: [PERMISSION_TYPE.READ],
            requiresAuth: true,
            breadcrumb: [
              {
                name: 'Danh sách Report',
              },
              {
                name: 'Report',
              },
              {
                name: 'Danh sách Report',
                active: true,
              },
            ],
          },
        },
        {
          path: 'report2',
          name: 'report2',
          component: () => import('@/view/pages/Report/Report2.vue'),
          meta: {
            pageCode: PAGE_CODE.REPORT,
            permissions: [PERMISSION_TYPE.READ],
            requiresAuth: true,
            breadcrumb: [
              {
                name: 'Danh sách Report',
              },
              {
                name: 'Report',
              },
              {
                name: 'Danh sách Report',
                active: true,
              },
            ],
          },
        },
        {
          path: 'report3',
          name: 'report3',
          component: () => import('@/view/pages/Report/Report3.vue'),
          meta: {
            pageCode: PAGE_CODE.REPORT,
            permissions: [PERMISSION_TYPE.READ],
            requiresAuth: true,
            breadcrumb: [
              {
                name: 'Danh sách Report',
              },
              {
                name: 'Report',
              },
              {
                name: 'Danh sách Report',
                active: true,
              },
            ],
          },
        },
        {
          path: 'report4',
          name: 'report4',
          component: () => import('@/view/pages/Report/Report4.vue'),
          meta: {
            pageCode: PAGE_CODE.REPORT,
            permissions: [PERMISSION_TYPE.READ],
            requiresAuth: true,
            breadcrumb: [
              {
                name: 'Danh sách Report',
              },
              {
                name: 'Report',
              },
              {
                name: 'Danh sách Report',
                active: true,
              },
            ],
          },
        },
        // LEVEL: END
        // QUIZE: BEGIN
        {
          path: 'quize',
          name: 'quize',
          component: () => import('@/view/pages/quize/Quize.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.QUIZ,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý câu hỏi',
              },
              {
                name: 'Danh sách câu hỏi',
              },
              {
                name: 'Ngân hàng câu hỏi',
                active: true,
              },
            ],
          },
        },
        // QUIZE: END
        // COACHES & EXPERTS: BEGIN
        {
          path: 'coaches_experts',
          name: 'coaches_experts',
          component: () =>
            import('@/view/pages/coaches_experts/coaches_experts.vue'),

          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.COACHES_EXPERTS,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Theo dõi từng khách hàng',
              },
              {
                name: 'Danh sách khách hàng',
              },
              {
                name: 'Dashboard khách hàng',
                active: true,
              },
            ],
          },
        },
        {
          path: 'coach/create',
          name: 'coach_create',
          component: () => import('@/view/pages/coach/CoachCreate.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.TRAINING_GROUP,
            permissions: [
              PERMISSION_TYPE.WRITE_ALL,
              PERMISSION_TYPE.WRITE_OWNER,
            ],
            breadcrumb: [
              {
                name: 'Quản lý nhóm huấn luyện',
                link: 'CoachList',
              },
              {
                name: 'Thêm mới nhóm huấn luyện',
              },
              {
                name: 'Thêm mới nhóm huấn luyện',
                active: true,
              },
            ],
          },
        },
        {
          path: 'coach/edit/:id',
          name: 'coach_edit',
          component: () => import('@/view/pages/coach/CoachUpdate.vue'),
          props: (route) => ({
            id: route.params.id,
          }),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.TRAINING_GROUP,
            permissions: [
              PERMISSION_TYPE.WRITE_ALL,
              PERMISSION_TYPE.WRITE_OWNER,
            ],
            breadcrumb: [
              {
                name: 'Quản lý nhóm huấn luyện',
                link: 'CoachList',
              },
              {
                name: 'Chỉnh sửa nhóm huấn luyện',
              },
              {
                name: 'Chỉnh sửa nhóm huấn luyện',
                active: true,
              },
            ],
          },
        },
        {
          path: 'coach/:id',
          name: 'coach_detail',
          component: () => import('@/view/pages/coach/CoachDetail.vue'),
          props: (route) => ({
            id: route.params.id,
          }),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.TRAINING_GROUP,
            permissions: [PERMISSION_TYPE.READ_ALL, PERMISSION_TYPE.READ_OWNER],
            breadcrumb: [
              {
                name: 'Quản lý nhóm huấn luyện',
                link: 'CoachList',
              },
              {
                name: 'Chi tiết nhóm huấn luyện',
              },
              {
                name: 'Chi tiết nhóm huấn luyện',
                active: true,
              },
            ],
          },
        },
        {
          path: 'coach_customers',
          name: 'CoachCustomers',
          component: () => import('@/view/pages/group_dashboard/index.vue'),

          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.MY_CUSTOMER,
            permissions: [PERMISSION_TYPE.READ_ALL, PERMISSION_TYPE.READ_OWNER],
            breadcrumb: [
              {
                name: 'Khách hàng của tôi',
              },
              {
                name: 'Danh sách khách hàng',
              },
              {
                name: 'Danh sách khách hàng',
                active: true,
              },
            ],
          },
        },

        // COACHES & EXPERTS: END
        // COACHES CALENDAR: BEGIN
        {
          path: 'coaches_calendar',
          name: 'coaches_calendar',
          component: () =>
            import('@/view/pages/coaches_calendar/Coaches_Calendar.vue'),

          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.COACHES_CALENDAR,
            permissions: [PERMISSION_TYPE.READ,PERMISSION_TYPE.WRITE_OWNER],
            breadcrumb: [
              {
                name: 'Quản lý đặt lịch',
              },
              {
                name: 'Danh sách đặt lịch',
                active: true,
              },
            ],
          },
        },


        // COACHES CALENDAR: END
        // COACH: BEGIN
        {
          path: 'coach',
          name: 'CoachList',
          component: () => import('@/view/pages/coach/CoachList.vue'),

          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.TRAINING_GROUP,
            permissions: [PERMISSION_TYPE.READ_ALL, PERMISSION_TYPE.READ_OWNER],
            breadcrumb: [
              {
                name: 'Quản lý nhóm huấn luyện',
              },
              {
                name: 'Danh sách nhóm huấn luyện',
              },
              {
                name: 'Danh sách nhóm huấn luyện',
                active: true,
              },
            ],
          },
        },
        {
          path: 'coaches_experts/:id',
          name: 'coaches_experts_detail',
          component: () => import('@/view/pages/coaches_experts/detail.vue'),
        },
        // COACH: END
        // MOVEMENT ROUTE: BEGIN
        {
          path: 'movement_route',
          name: 'movement_route_list',
          component: () =>
            import('@/view/pages/movement_route/MovementRouteList.vue'),

          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.AGENDA,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý lộ trình',
              },
              {
                name: 'Danh sách lộ trình',
              },
              {
                name: 'Danh sách lộ trình',
                active: true,
              },
            ],
          },
        },
        {
          path: 'movement_route/detail/:id',
          name: 'movement_route_detail',
          component: () =>
            import('@/view/pages/movement_route/MovementRouteDetail.vue'),

          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.AGENDA,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý lộ trình',
                link: 'movement_route_list',
              },
              {
                name: 'Chi tiết lộ trình',
              },
              {
                name: 'Chi tiết lộ trình',
                active: true,
              },
            ],
          },
        },
        {
          path: 'agenda/detail/:id',
          name: 'agenda_detail',
          component: () =>
            import('@/view/pages/movement_route/OtherSchedule.vue'),

          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.AGENDA,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý lộ trình',
                link: 'movement_route_list',
              },
              {
                name: 'Chi tiết lộ trình',
              },
              {
                name: 'Chi tiết lộ trình',
                active: true,
              },
            ],
          },
        },
        {
          path: 'movement_route/detail/:id/:lessonId',
          name: 'movement_route_lesson_detail',
          component: () =>
            import('@/view/pages/movement_route/MovementRouteLessonDetail.vue'),
          props: (route) => ({
            id: route.params.id,
            lessonId: route.params.lessonId,
          }),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.AGENDA,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý lộ trình vận động',
                link: 'movement_route_list',
              },
              {
                name: 'Chi tiết lộ trình',
                link: 'movement_route_detail',
              },
              {
                name: 'Chi tiết bài tập',
              },
              {
                name: 'Chi tiết bài tập',
                active: true,
              },
            ],
          },
        },
        {
          path: 'movement_route/create/:id/:day',
          name: 'movement_route_lesson_create',
          component: () =>
            import('@/view/pages/movement_route/MovementRouteLessonEdit.vue'),

          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.AGENDA,
            permissions: [PERMISSION_TYPE.WRITE],
            breadcrumb: [
              {
                name: 'Quản lý lộ trình',
                link: 'movement_route_list',
              },
              {
                name: 'Chi tiết lộ trình',
                link: 'movement_route_detail',
              },
              {
                name: 'Tạo mới bài tập',
              },
              {
                name: 'Tạo mới bài tập',
                active: true,
              },
            ],
          },
        },
        {
          path: 'movement_route/edit/:id/:lessonId',
          name: 'movement_route_lesson_edit',
          component: () =>
            import('@/view/pages/movement_route/MovementRouteLessonEdit.vue'),

          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.AGENDA,
            permissions: [PERMISSION_TYPE.WRITE],
            breadcrumb: [
              {
                name: 'Quản lý lộ trình',
                link: 'movement_route_list',
              },
              {
                name: 'Chi tiết lộ trình',
                link: 'movement_route_detail',
              },
              {
                name: 'Chỉnh sửa bài tập',
              },
              {
                name: 'Chỉnh sửa bài tập',
                active: true,
              },
            ],
          },
        },
        // MOVEMENT ROUTE: END
        // CALENDAR: BEGIN
        {
          path: 'calendar',
          name: 'calendar_list',
          component: () => import('@/view/pages/calendar/CalendarList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.CALENDAR,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Lịch làm việc',
              },
              {
                name: 'Danh sách lịch làm việc',
              },
              {
                name: 'Lịch làm việc',
                active: true,
              },
            ],
          },
        },
        // CALENDAR: END
        // ZOOM: BEGIN
        {
          path: 'zoom_video',
          name: 'zoom_login',
          component: () => import('@/view/pages/zoom_video/ZoomLogin.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.ZOOMLOGIN,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Zoom Video',

              },
              {
                name: 'Preview',
                // active: true
              },
              {
                name: 'Preview',
                active: true,
              },
            ],
          },
        },
        // ZOOM : END
        // WEB OF COMMUNICATION: BEGIN
        {
          path: 'communication',
          name: 'communication_list',
          component: () =>
            import('@/view/pages/communication/communicationList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.COMMUNICATION,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Thông báo',
              },
              {
                name: 'Danh sách thông báo',
              },
              {
                name: 'Danh sách thông báo',
                active: true,
              },
            ],
          },
        },
        {
          path: 'communication/detail/:id',
          name: 'communication_detail',
          component: () =>
            import('@/view/pages/communication/communicationDetail.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.COMMUNICATION,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Thông báo',
                link: 'communication_list',
              },
              {
                name: 'Xem chi tiết thông báo',
              },
              {
                detailName: 'Xem chi tiết thông báo',
                active: true,
              },
            ],
          },
        },
        {
          path: 'communication/:form_type/:id?',
          name: 'communication_create',
          component: () =>
            import('@/view/pages/communication/communicationForm.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.COMMUNICATION,
            permissions: [PERMISSION_TYPE.WRITE],
            breadcrumb: [
              {
                name: 'Thông báo',
                link: 'communication_list',
              },
              {
                createName: 'Thiết lập thông báo mới',
                editName: 'Chỉnh sửa thông báo',
              },
              {
                createName: 'Thiết lập thông báo mới',
                editName: 'Chỉnh sửa thông báo',
                active: true,
              },
            ],
          },
        },
        // {
        //   path: 'communication/:form_type/:id?',
        //   name: 'communication_update',
        //   component: () =>
        //     import('@/view/pages/communication/communicationForm.vue'),
        //   meta: {
        //     breadcrumb: [
        //       {
        //         name: 'Cổng giao tiếp',
        //         link: 'communication_list',
        //       },
        //       {
        //         createName: 'Thiết lập thông báo mới',
        //         editName: 'Chỉnh sửa thông báo',
        //         link: 'communication_create',
        //         active: true,
        //       },
        //     ],
        //   },
        // },
        // WEB OF COMMUNICATION: END
        // SURVEY: BEGIN
        {
          path: 'survey',
          name: 'survey_list',
          component: () => import('@/view/pages/survey/surveyList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.SURVEY,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý khảo sát',
              },
              {
                name: 'Danh sách khảo sát',
              },
              {
                name: 'Danh sách khảo sát',
                active: true,
              },
            ],
          },
        },
        {
          path: 'survey/create',
          name: 'survey_create',
          component: () => import('@/view/pages/survey/surveyCreate.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.SURVEY,
            permissions: [PERMISSION_TYPE.WRITE],
            breadcrumb: [
              {
                name: 'Quản lý khảo sát',
                link: 'survey_list',
              },
              {
                name: 'Thêm mới khảo sát',
              },
              {
                name: 'Tạo khảo sát mới',
                active: true,
              },
            ],
          },
        },
        {
          path: 'survey/:id',
          name: 'survey_detail',
          component: () => import('@/view/pages/survey/surveyDetail.vue'),
          props: (route) => ({
            id: route.params.id,
          }),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.SURVEY,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý khảo sát',
                link: 'survey_list',
              },
              {
                name: 'Chi tiết khảo sát',
              },
              {
                name: 'Chi tiết khảo sát',
                active: true,
              },
            ],
          },
        },
        {
          path: 'survey/edit/:id',
          name: 'survey_edit',
          component: () => import('@/view/pages/survey/surveyEdit.vue'),
          props: (route) => ({
            id: route.params.id,
          }),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.SURVEY,
            permissions: [PERMISSION_TYPE.WRITE],
            breadcrumb: [
              {
                name: 'Quản lý khảo sát',
                link: 'survey_list',
              },
              {
                name: 'Chỉnh sửa khảo sát',
              },
              {
                name: 'Chỉnh sửa khảo sát',
                active: true,
              },
            ],
          },
        },
        // SURVEY: END
        // SURVEY_QUIZE: BEGIN
        {
          path: 'survey_quize',
          name: 'survey_quize_list',
          component: () =>
            import('@/view/pages/survey_quize/surveyQuizeList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.SURVEY_QUESTION_GROUP,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý câu hỏi',
                link: 'survey_quize_list',
              },
              {
                name: 'Danh sách bộ câu hỏi',
              },
              {
                name: 'Bộ câu hỏi',
                active: true,
              },
            ],
          },
        },
        {
          path: 'survey_quize/create',
          name: 'survey_quize_create',
          component: () =>
            import('@/view/pages/survey_quize/surveyQuizeCreate.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.SURVEY_QUESTION_GROUP,
            permissions: [PERMISSION_TYPE.WRITE],
            breadcrumb: [
              {
                name: 'Quản lý bộ câu hỏi',
                link: 'survey_quize_list',
              },
              {
                name: 'Tạo mới bộ câu hỏi',
              },
              {
                name: 'Tạo mới bộ câu hỏi',
                active: true,
              },
            ],
          },
        },
        {
          path: 'survey_quize/:id',
          name: 'survey_quize_detail',
          component: () =>
            import('@/view/pages/survey_quize/surveyQuizeDetail.vue'),
          props: (route) => ({
            id: route.params.id,
          }),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.SURVEY_QUESTION_GROUP,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý bộ câu hỏi',
                link: 'survey_quize_list',
              },
              {
                name: 'Chi tiết bộ câu hỏi',
              },
              {
                name: 'Chi tiết bộ câu hỏi',
                active: true,
              },
            ],
          },
        },
        {
          path: 'survey_quize/edit/:id',
          name: 'survey_quize_edit',
          component: () =>
            import('@/view/pages/survey_quize/surveyQuizeEdit.vue'),
          props: (route) => ({
            id: route.params.id,
          }),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.SURVEY_QUESTION_GROUP,
            permissions: [PERMISSION_TYPE.WRITE],
            breadcrumb: [
              {
                name: 'Quản lý bộ câu hỏi',
                link: 'survey_quize_list',
              },
              {
                name: 'Chỉnh sửa bộ câu hỏi',
              },
              {
                name: 'Chỉnh sửa bộ câu hỏi',
                active: true,
              },
            ],
          },
        },
        // SURVEY_QUIZE: END
        // SURVEY_RÉULT: BEGIN
        {
          path: 'survey_result',
          name: 'survey_result_list',
          component: () =>
            import('@/view/pages/survey_result/surveyResultList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.SURVEY_QUESTION_GROUP,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý khảo sát',
                link: 'survey_result_list',
              },
              {
                name: 'Kết quả khảo sát',
              },
              {
                name: 'Danh sách kết quả khảo sát',
                active: true,
              },
            ],
          },
        },
        // SURVEY_RÉULT: END
        // SYSTEM PARAMETER: BEGIN
        {
          path: 'system_parameter',
          name: 'system_parameter',
          component: () =>
            import('@/view/pages/system_parameter/systemParameterList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.COMMON_CONFIGURE_TASK_PARAMETER,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Tham số hệ thống',
              },
              {
                name: 'Danh sách tham số hệ thống',
              },
              {
                name: 'Danh sách tham số hệ thống',
                active: true,
              },
            ],
          },
        },
        // SYSTEM PARAMETER: END
        // GLUCOSE: BEGIN
        {
          path: 'glucose',
          name: 'glucose_list',
          component: () => import('@/view/pages/glucose/glucoseList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.GLUCOSE,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Ngưỡng đường huyết',
              },
              {
                name: 'Danh sách ngưỡng đường huyết',
              },
              {
                name: 'Danh sách ngưỡng đường huyết',
                active: true,
              },
            ],
          },
        },
        // GLUCOSE: END
        // EXERCISE: BEGIN
        {
          path: 'exercise',
          name: 'exercise_list',
          component: () => import('@/view/pages/exercise/exerciseList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.EXERCISE,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý vận động',
              },
              {
                name: 'Danh sách vận động',
              },
              {
                name: 'Danh sách vận động',
                active: true,
              },
            ],
          },
        },
        {
          path: 'exercise/:form_type/:id?',
          name: 'exercise_detail',
          props: true,
          component: () => import('@/view/pages/exercise/exerciseDetail.vue'),
          meta: {
            pageCode: PAGE_CODE.EXERCISE,
            permissions: [PERMISSION_TYPE.WRITE],
          },
        },
        // EXERCISE: END
        // FOOD: BEGIN
        {
          path: 'food',
          name: 'food_list',
          component: () => import('@/view/pages/food/foodList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.FOOD_CATEGORY,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý món ăn',
              },
              {
                name: 'Danh sách món ăn',
              },
              {
                name: 'Danh sách món ăn',
                active: true,
              },
            ],
          },
        },
        {
          path: 'food/:form_type/:id?',
          name: 'food_detail',
          props: true,
          component: () => import('@/view/pages/food/foodDetail.vue'),
          meta: {
            pageCode: PAGE_CODE.FOOD_CATEGORY,
            permissions: [PERMISSION_TYPE.WRITE],
          },
        },
        // FOOD: END
        // MENU: BEGIN
        {
          path: 'menu',
          name: 'menu_list',
          component: () => import('@/view/pages/menu/menuList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.FOOD_MENU,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý thực đơn mẫu',
              },
              {
                name: 'Danh sách thực đơn mẫu',
              },
              {
                name: 'Danh sách thực đơn mẫu',
                active: true,
              },
            ],
          },
        },
        {
          path: 'menu/detail/:id',
          name: 'menu_detail',
          props: true,
          component: () => import('@/view/pages/menu/menuDetail.vue'),
          meta: {
            pageCode: PAGE_CODE.FOOD_MENU,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý thực đơn mẫu',
                link: 'menu_list',
              },
              {
                name: 'Chi tiết thực đơn mẫu',
              },
              {
                name: 'Chi tiết thực đơn mẫu',
                active: true,
              },
            ],
          },
        },
        {
          path: 'menu/edit/:id',
          name: 'menu_edit',
          props: (route) => ({
            id: route.params.id,
          }),
          component: () => import('@/view/pages/menu/menuEdit.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.FOOD_MENU,
            permissions: [PERMISSION_TYPE.WRITE],
            breadcrumb: [
              {
                name: 'Quản lý thực đơn mẫu',
                link: 'menu_list',
              },
              {
                name: 'Chỉnh sửa thực đơn',
              },
              {
                name: 'Chỉnh sửa thực đơn',
                active: true,
              },
            ],
          },
        },
        // MENU: END
        // WEIGHT: BEGIN
        {
          path: 'weight',
          name: 'weight_list',
          component: () =>
            import('@/view/pages/bmi_and_weight/bmiAndWeightList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.BMI,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Ngưỡng BMI / Cân nặng',
              },
              {
                name: 'Danh sách ngưỡng BMI / Cân nặng',
              },
              {
                name: 'Ngưỡng BMI / Cân nặng',
                active: true,
              },
            ],
          },
        },
        // WEIGHT: END
        // FEELING: BEGIN
        {
          path: 'feeling',
          name: 'feeling_list',
          component: () => import('@/view/pages/feeling/feeling.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.EMOTION,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Cảm xúc',
              },
              {
                name: 'Danh sách cảm xúc',
              },
              {
                name: 'Cảm xúc',
                active: true,
              },
            ],
          },
        },
        // FEELING: END
        // TIME FRAME OF OPERATION : BEGIN
        {
          path: 'time_frame_of_operation',
          name: 'time_frame_of_operation_list',
          component: () =>
            import(
              '@/view/pages/time_frame_of_operation/timeFrameOfOperationList.vue'
            ),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.TIME_FRAME,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Khung giờ và thời gian hoạt động',
              },
              {
                name: 'Danh sách khung giờ và thời gian hoạt động',
              },
              {
                name: 'Khung giờ và thời gian hoạt động',
                active: true,
              },
            ],
          },
        },
        // TIME FRAME OF OPERATION : END
        // CONTENT NOTIFICATION OF KPIS: BEGIN
        {
          path: 'content',
          name: 'content',
          component: () => import('@/view/pages/content/content.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.COMMON_CONFIGURE_TASK_CONTENT,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản trị nội dung',
              },
              {
                name: 'Danh sách nội dung',
              },
              {
                name: 'Quản trị nội dung',
                active: true,
              },
            ],
          },
        },
        // CONTENT NOTIFICATION OF KPIS: END
        // PROFILE PAGE: BEGIN
        {
          path: 'profile',
          name: 'profile',
          component: () => import('@/view/pages/profile/index.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Profile',
                link: 'profile',
                active: true,
              },
            ],
          },
        },
        // COURSE: BEGIN
        {
          path: 'course',
          name: 'course',
          component: () => import('@/view/pages/course/course.vue'),
          meta: {
            breadcrumb: [
              {
                name: 'Khoá học',
                link: 'course',
                active: true,
              },
            ],
          },
        },
        {
          path: 'course/:id?',
          name: 'course_detail',
          props: true,
          component: () => import('@/view/pages/course/courseDetail.vue'),
          meta: {
            requiresAuth: true,
            breadcrumb: [
              {
                name: 'Khoá học',
                link: 'course',
              },
              {
                name: 'Chi tiết khoá học',
                link: 'course_detail',
                active: true,
              },
            ],
          },
        },
        // PROFILE PAGE: END
        // FEATURED ARTICLE: BEGIN
        {
          path: 'article',
          name: 'article',
          component: () => import('@/view/pages/article/article.vue'),
          meta: {
            pageCode: PAGE_CODE.LEARNING_POST,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Bài viết nổi bật',
              },
              {
                name: 'Danh sách bài viết',
              },
              {
                name: 'Bài viết nổi bật',
                active: true,
              },
            ],
          },
        },
        // FEATURED ARTICLE: END
        // USER GUIDE : BEGIN
        {
          path: 'user_guide',
          name: 'user_guide',
          component: () => import('@/view/pages/guide/guide.vue'),
          meta: {
            pageCode: PAGE_CODE.PROFILE_INSTRUCTION,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Hướng dẫn sử dụng',
              },
              {
                name: 'Danh sách hướng dẫn sử dụng',
              },
              {
                name: 'Hướng dẫn sử dụng',
                active: true,
              },
            ],
          },
        },
        // USER GUIDE: END
        // QUESTION RESPONSE : BEGIN
        {
          path: 'question_response',
          name: 'question_response',
          component: () =>
            import('@/view/pages/question_response/QuestionResponseList.vue'),
          meta: {
            pageCode: PAGE_CODE.QUESTION,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Hỏi đáp chuyên gia',
              },
              {
                name: 'Danh sách câu hỏi',
              },
              {
                name: 'Danh sách câu hỏi',
                active: true,
              },
            ],
          },
        },
        // QUESTION RESPONSE: END
        // CUSTOMER CENTER
        {
          path: 'customer_center',
          name: 'customer_center',
          component: () => import('@/view/pages/customer_center/CustomerCenterList'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.CUSTOMER_CENTER,
            permissions: [
                PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý khách hàng trung tâm',
              },
              {
                name: 'Danh sách khách hàng trung tâm',
              },
              {
                name: 'Danh sách khách hàng trung tâm',
                active: true,
              },
            ],
          },
        },
        // Courses: BEGIN
        {
          path: 'courses',
          name: 'courses_list',
          component: () => import('@/view/pages/courses/CoursesList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.COURSES,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý Khách hàng',
              },
              {
                name: 'Danh sách khóa học',
              },
              {
                name: 'Danh sách khóa học',
                active: true,
              },
            ],
          },
        },
        {
          path: 'courses',
          name: 'courses_list_filter',
          component: () => import('@/view/pages/courses/CoursesListFilter.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.COURSES,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý Courses',
              },
              {
                name: 'Danh sách Courses',
              },
              {
                name: 'Danh sách courses',
                active: true,
              },
            ],
          },
        },
        {
          path: 'courses/create',
          name: 'courses_create',
          // props: {
          // default: true,
          // form_type: "create" // Set props to pass to the component
          // },
          props: true,
          component: () =>
            import('@/view/pages/courses/CoursesCreate.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.COURSES,
            permissions: [
              PERMISSION_TYPE.WRITE_ALL
            ],
            breadcrumb: [
            {
              name: 'Quản lý Khách hàng',
            link: 'courses_list',
              },
            {
              name: 'Thêm mới',
              },
            {
              name: 'Thêm mới ',
            link: 'courses_create',
            active: true,
              },
            ],
          },
        },
        {
          path: 'courses/:id?',
          name: 'courses_view',
          props: true,
          component: () => import('@/view/pages/courses/CoursesView.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.COURSES,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý khóa học',
                link: 'courses_list',
              },
              {
                name: 'Chi tiết khóa học',
              },
              {
                name: 'Chi tiết khóa học',
                active: true,
              },
            ],
          },
        },
        {
          path: 'courses/:form_type/:id?',
          name: 'courses_edit',
          props: true,
          component: () =>
            import('@/view/pages/courses/CoursesUpdate.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.COURSES,
            permissions: [
              PERMISSION_TYPE.WRITE_ALL,
            ],
            breadcrumb: [
              {
                name: 'Quản lý Khách hàng',
              link: 'courses_list',
                },
              {
                name: 'Chỉnh sửa',
                },
              {
              name: 'Chỉnh sửa ',
              link: 'courses_create',
              active: true,
                },
            ],
          },
        },
        // Courses: END
        // CustomerReceives: BEGIN
        {
          path: 'customer_receives',
          name: 'customer_receives_list',   
          component: () => import('@/view/pages/customer_receives/CustomerReceivesList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.CUSTOMER_RECEIVES,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý khách hàng',
              },
              {
                name: 'Danh sách tiếp nhận',
              },
              {
                name: 'Danh sách tiếp nhận',
                active: true,
              },
            ],
          },
        },
        {
          path: 'customer_receives/:id?',
          name: 'customer_receives_view',
          props: true,
          component: () => import('@/view/pages/customer_receives/CustomerReceivesView.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.CUSTOMER_RECEIVES,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý CustomerReceives',
                link: 'customer_receives_list',
              },
              {
                name: 'Chi tiết CustomerReceives',
              },
              {
                name: 'Chi tiết CustomerReceives',
                active: true,
              },
            ],
          },
        },
        // CustomerReceives: END
        // MessageTemplates: BEGIN
        {
          path: 'message_templates',
          name: 'message_templates_list',
          component: () => import('@/view/pages/message_templates/MessageTemplatesList.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.MESSAGE_TEMPLATES,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý template tin nhắn',
              },
              {
                name: 'Danh sách template tin nhắn',
              },
              {
                name: 'Danh sách template tin nhắn',
                active: true,
              },
            ],
          },
        },
        {
          path: 'message_templates',
          name: 'message_templates_list_filter',
          component: () => import('@/view/pages/message_templates/MessageTemplatesListFilter.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.MESSAGE_TEMPLATES,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý MessageTemplates',
              },
              {
                name: 'Danh sách MessageTemplates',
              },
              {
                name: 'Danh sách messageTemplates',
                active: true,
              },
            ],
          },
        },
        {
          path: 'message_templates/create',
          name: 'message_templates_create',
          props: {
            form_type: "create" // Set props to pass to the component
          },
          component: () =>
            import('@/view/pages/message_templates/MessageTemplatesCreate.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.MESSAGE_TEMPLATES,
            permissions: [
              PERMISSION_TYPE.WRITE_ALL
            ],
            breadcrumb: [
              {
                name: 'Quản lý MessageTemplates',
                link: 'message_templates_list',
              },
              {
                name: 'Thêm mới MessageTemplates',
              },
              {
                name: 'Tạo mới MessageTemplates',
                link: 'message_templates_create',
                active: true,
              },
            ],
          },
        },
        {
          path: 'message_templates/:id?',
          name: 'message_templates_view',
          props: true,
          component: () => import('@/view/pages/message_templates/MessageTemplatesView.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.MESSAGE_TEMPLATES,
            permissions: [PERMISSION_TYPE.READ],
            breadcrumb: [
              {
                name: 'Quản lý MessageTemplates',
                link: 'message_templates_list',
              },
              {
                name: 'Chi tiết MessageTemplates',
              },
              {
                name: 'Chi tiết MessageTemplates',
                active: true,
              },
            ],
          },
        },
        {
          path: 'message_templates/:form_type/:id?',
          name: 'message_templates_edit',
          props: true,
          component: () =>
            import('@/view/pages/message_templates/MessageTemplatesUpdate.vue'),
          meta: {
            requiresAuth: true,
            pageCode: PAGE_CODE.MESSAGE_TEMPLATES,
            permissions: [
              PERMISSION_TYPE.WRITE_ALL,
            ],
            breadcrumb: [
              {
                name: 'Danh mục MessageTemplates',
                link: 'message_templates_list',
              },
              {
                createName: 'Thêm mới MessageTemplates',
                editName: 'Chỉnh sửa MessageTemplates',
                link: 'message_templates_edit',
                active: true,
              },
            ],
          },
        },
      ],
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && store.state.auth.isAuthenticated) {
    store.dispatch(RESET_LAYOUT_CONFIG);

    const { pageCode, permissions: pagePermisstions } = to.meta;

    if (pageCode) {
      let permissions = store.state.auth.permissions;

      if (!permissions.length) {
        const groupPermissionByCode = groupBy(
          JSON.parse(localStorage.getItem('permissions')),
          'group',
        );
        for (const property in groupPermissionByCode) {
          permissions.push({
            code: property,
            value: groupPermissionByCode[property],
          });
        }
        await store.commit(SET_PERMISSIONS, permissions);
      }

      const permissionWithPage = permissions
        .find((el) => el.code === pageCode)
        ?.value.map((el) => el.nameType);

      if (intersection(pagePermisstions, permissionWithPage).length) {
        to.meta.isWritePermission = permissionWithPage.includes(
          PERMISSION_TYPE.WRITE,
        );
        to.meta.isWriteAllPermission = permissionWithPage.includes(
          PERMISSION_TYPE.WRITE_ALL,
        );
        to.meta.isWriteOwnerPermission = permissionWithPage.includes(
          PERMISSION_TYPE.WRITE_OWNER,
        );
        to.meta.isPartWritePermission = permissionWithPage.includes(
          PERMISSION_TYPE.WRITE_1_PART,
        );
        next();
      } else {
        alert('Bạn không có quyền truy cập. Nhấn OK để quay lại');
        next(from.path);
      }
      next();
    } else {
      next();
    }

    // Scroll page to top on every route change
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  } else {
    let mgs = new MgrService();
    await mgs.signOut();
  }
  // reset config to initial state
});

export default router;